import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";

import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

// import contactBgImage from '../images/pages/contact/banner.jpg';

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="post-paragraph">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
}


const ContactUs = ({ data }) => {
  const { pageTitle, metaTitle, contactUsBgImage, byPhone, byEmail, byPost } = data.contentfulContactUs;

  return (
    <Layout>
      <SEO title={metaTitle} />
        <div className="hero-shallow" style={{backgroundImage:`url(${contactUsBgImage.file.url})`}}>
          <div className="hero-overlay"></div>
            <Container>
              <Row>
                <Col md={12}>
                  <div className="hero-text-container">
                    <h1>{pageTitle}</h1>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
        <div className="contact-us full-width-layout">
          <Container fluid={true}>
            <h2>Say hello!</h2>
            <p className="large-paragraph">If you have any questions about The Eventa Group, then please feel free to contact us using any of the following methods.</p>
            <Row>
              <Col sm={12} lg={4}>
                {documentToReactComponents(byPhone.json, options)}
              </Col>
              <Col sm={12} lg={4}>
                {documentToReactComponents(byEmail.json, options)}
              </Col>
              <Col sm={12} lg={4}>
                {documentToReactComponents(byPost.json, options)}
              </Col>
            </Row>
          </Container>
        </div>
    </Layout>
  );
};

export default ContactUs;

export const pageQuery = graphql`
  query {
    contentfulContactUs {
      contactUsBgImage {
        file {
          url
        }
      }
      byEmail {
        json
      }
      byPhone {
        json
      }
      byPost {
        json
      }
      metaTitle
      pageTitle
      slug
    }
  }
`;